<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2">
      <div class="mb-2">
        <a-button @click="$router.back()">
          Regresar
        </a-button>
      </div>
      <form @submit.prevent="update(classroom).then(() => $router.back())" @keydown="removeError($event.target.id)">
            <h3 class="text-2xl my-4">Actualizar aula</h3>

                  <a-input
                    label="Código"
                    required
                    class="mb-4"
                    id="code"
                    :error="error('code')"
                    v-model="classroom.code" />

                  <a-input
                    label="Descripción"
                    class="mb-4"
                    required
                    id="description"
                    :error="error('description')"
                    v-model="classroom.description" />

                  <a-input
                    label="Máxima capacidad"
                    required
                    class="mb-4"
                    id="max_capacity"
                    type="number"
                    :error="error('max_capacity')"
                    v-model.number="classroom.max_capacity" />


                  <label class="mb-2 text-gray-700 block font-medium" for="allow_many_sections">¿Permite múltiples secciones?</label>
                  <a-switch class="mb-4" id="allow_many_sections" v-model="classroom.allow_many_sections" />

                  <label class="mb-2 text-gray-700 block font-medium" for="enabled">Activa</label>
                  <a-switch class="mb-4" id="enabled" v-model="classroom.enabled" />



            <div class="flex space-x-2">
              <a-button mode="primary" type="submit" :loading="loading" :disabled="loading">
                Actualizar cambios
              </a-button>

              <a-button outlined :to="{ name: 'classrooms' }">
                Cancelar
              </a-button>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    classroom: {
      code: '',
      description: '',
      max_capacity: 0,
      allow_many_sections: false,
      enabled: false
    }
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      classrooms: state => state.classrooms.pagination.data,
      currentClassroomOnState: state => state.classrooms.classroom,
      loading: state => state.classrooms.loading
    })
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    ...mapActions({
      fetchClassroom: 'classrooms/show',
      update: 'classrooms/update',
      removeError: 'removeError',
    })
  },
  watch: {
    currentClassroomOnState(val) {
      this.classroom = {...val}
    }
  },
  created() {
    const { id } = this.$route.params
    this.fetchClassroom({ id })
  }
}
</script>
