<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2">
      <div class="mb-2">
        <a-button @click="$router.back()">
          {{ lang.common.back }}
        </a-button>
      </div>
      <div class="flex">
        <div class="w-full">

          <form @submit.prevent="store(classroom).then(() => $router.back())" @keydown="removeError($event.target.id)">
            <h3 class="text-2xl my-4">Crear aula</h3>

            <a-input
              id="code"
              :error="error('code')"
              label="Código"
              required
              class="mb-4"
              v-model="classroom.code" />

            <a-input
              class="mb-4"
              label="Descripción"
              required
              id="description"
              :error="error('description')"
              v-model="classroom.description" />

            <a-input
              label="Máxima capacidad"
              class="mb-4"
              required
              id="max_capacity"
              type="number"
              :error="error('max_capacity')"
              v-model.number="classroom.max_capacity" />

            <label class="text-gray-700 block font-medium mb-2" for="allow_many_sections">¿Permite múltiples secciones?</label>
            <a-switch id="allow_many_sections" v-model="classroom.allow_many_sections" class="mb-4" />

            <label class="text-gray-700 block font-medium mb-2" for="enabled">Activa</label>
            <a-switch id="enabled" v-model="classroom.enabled" class="mb-4" />

            <div class="flex space-x-2">
              <a-button mode="primary" type="submit" :loading="loading">
                {{ lang.common.save }}
              </a-button>

              <a-button outlined :to="{ name: 'classrooms' }">
                {{ lang.common.cancel }}
              </a-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    classroom: {
      code: '',
      description: '',
      max_capacity: 0,
      allow_many_sections: false,
      enabled: false
    },
    isUpdating: false
  }),
  computed: {
    ...mapGetters(['hasError', 'error', 'lang']),
    ...mapState({
      loading: state => state.classrooms.loading
    })
  },
  methods: {
    ...mapActions({
      fetchClassroom: 'classrooms/show',
      store: 'classrooms/store',
      update: 'classrooms/update',
      removeError: 'removeError',
    })
  }
}
</script>
